import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import { getCurrentUser } from "../firebase";
import store from "../store";
import {
    getProvider,
    performReauthentication,
    performOAuthLoginWithProvider,
} from "./authentication";
import errorTranslate from "@shared/utils/errorTranslate";
import { SUPPORTED_LOCALES } from "@web/constants/language";
import { routerReplaceAfterLanguageChange } from "@web/utils/router";
import { clearBearerToken, setBearerToken } from "@web/api/config";
import { registerUser } from "@web/api/auth-api";
import { cloneDeep } from "lodash";
import { updateUserProfile } from "@web/auth/profile";
import {
    redirectAfterLogin,
    redirectNewUserToFinishSignUp,
    redirectToOrigin
} from "@web/auth/redirect";
import {
    storeMutationStatusCreator,
    storeGetterStatusCreator,
    storeStateStatusesCreator
} from "@web/utils/store-status-creator";
import router from '@web/router'

const { auth, functions } = firebase;

const authStatuses = [
    'signInStatus',
    'signUpStatus',
    'postSignUpStatus',
    'resetPasswordStatus',
    'updatePasswordStatus',
    'updateEmailStatus',
    'deleteAccountStatus',
    'verificationStatus',
]

const initialState = {
    initialized: false,
    isUserSignedIn: false,
    authUserMail: null,
    subscriber: null,
    ...storeStateStatusesCreator(authStatuses)
};

const actions = {
    async authStateChange({ commit, dispatch, state }) {
        const user = await getCurrentUser();

        if (!user) {
            commit('reset')

            store.commit('USER/RESET');

            if (!state.initialized) {
                let locale = SUPPORTED_LOCALES.find(item => navigator.language.includes(item));

                if (!locale) {
                    locale = SUPPORTED_LOCALES.find(item => navigator.languages.find(lang => lang.includes(item))) || "sk";
                }

                setTimeout(() => {
                    routerReplaceAfterLanguageChange(locale);
                }, 500);
            }
        } else {
            commit('setUserSignedIn', { authUserMail: user.email, isUserSignedIn: true })

            setBearerToken(user.token.token)

            try {
                await store.dispatch('USER/fetchProfile')

                if (router.currentRoute.name === 'auth-signin') {
                    redirectAfterLogin()
                }
            } catch (e) {
                if (e.response.status === 403) {
                    redirectNewUserToFinishSignUp()

                    return
                }

                dispatch('signOut')
            }

        }

        commit('initialized', true);
    },

    async signIn({ commit }, { email, password, remember }) {
        commit('setStatusLoading', 'signInStatus');

        const persistence = remember ? auth.Auth.Persistence.LOCAL : auth.Auth.Persistence.SESSION;

        try {
            await auth().setPersistence(persistence);
            await auth().signInWithEmailAndPassword(email, password);

            commit('setStatusSuccess', 'signInStatus');
        } catch (error) {
            const { message } = errorTranslate(error);

            commit('setStatusFail', { statusName: 'signInStatus', error: message })
        }
    },

    async signInWithProvider({ commit }, providerId) {
        commit('setStatusLoading', 'signInStatus')
        commit('setStatusLoading', 'signUpStatus')

        try {
            await auth().setPersistence(auth.Auth.Persistence.LOCAL)
            await performOAuthLoginWithProvider(getProvider(providerId))
        } catch (error) {
            const { message } = errorTranslate(error);

            commit('setStatusFail', { statusName: 'signInStatus', error: message })
            commit('setStatusFail', { statusName: 'signUpStatus', error: message })
        }
    },

    async signOut({ commit, state }) {
        await auth().signOut();

        if (state.subscriber) {
            state.subscriber()

            commit('setSubscriber', null)
        }

        clearBearerToken()
        redirectToOrigin();
    },

    async reauthenticate() {
        try {
            await performReauthentication();
        } catch (error) {
            throw errorTranslate(error);
        }
    },

    async signUp({ commit, dispatch }, { email, password }) {
        commit('setStatusLoading', 'signUpStatus')

        const data = {
            email,
            password
        };

        try {
            await functions().httpsCallable("webAuthSignUp")(data);

            commit('setStatusSuccess', 'signUpStatus')

            dispatch('signIn', { email, password, remember: true })
        } catch (error) {
            const { message } = errorTranslate(error);

            commit('setStatusFail', { statusName: 'signUpStatus', error: message })
        }
    },

    async registerNewUserAfterSignUp({ commit }, newProfile) {
        commit('setStatusLoading', 'postSignUpStatus')

        const user = await getCurrentUser();

        try {
            const { cookies, expertises, ...rest } = newProfile

            const { profile, cvSettings } = await registerUser({
                ...rest,
                expertises: expertises.map(exp => exp.expertiseId),
                email: user.email,
                avatar: user.photoURL,
                gdprAgreementAccepted: true
            })
            await functions().httpsCallable('createUserProfile')(cookies)

            commit('setStatusSuccess', 'postSignUpStatus')

            store.commit('USER/setProfile', profile)
            store.commit('USER/setCvSettings', cvSettings)

            redirectAfterLogin()
        } catch (e) {
            commit('setStatusFail', { statusName: 'postSignUpStatus', error: e.message })
        }
    },

    async resetPassword({ commit }, { email }) {
        commit('setStatusLoading', 'resetPasswordStatus')

        try {
            await functions().httpsCallable("webAuthGeneratePasswordResetLink")(email);

            commit('setStatusSuccess', 'resetPasswordStatus')
        } catch (error) {
            const { message } = errorTranslate(error);

            commit('setStatusFail', { statusName: 'resetPasswordStatus', error: message })
        }
    },

    async updateEmail({ commit, dispatch }, email) {
        commit('setStatusLoading', 'updateEmailStatus');

        const user = await getCurrentUser();

        try {
            await user.updateEmail(email);

            commit('setStatusSuccess', 'updateEmailStatus')

            updateUserProfile({ email })

            resetAuthStatusByNameDelayed('updateEmailStatus')
        } catch ({ code, message }) {
            if (code !== 'auth/requires-recent-login') {
                commit('setStatusFail', { statusName: 'updateEmailStatus', error: message });

                resetAuthStatusByNameDelayed('updateEmailStatus')
            } else {
                dispatch('reauthenticate')
                .then(() => dispatch("updateEmail", email))
                .catch(error => {
                    const { message } = errorTranslate(error);

                    commit('setStatusFail', { statusName: 'updateEmailStatus', error: message });

                    resetAuthStatusByNameDelayed('updateEmailStatus')
                });
            }
        }
    },

    async updatePassword({ commit, dispatch }, password) {
        commit('setStatusLoading', 'updatePasswordStatus')

        const user = await getCurrentUser();

        try {
            await user.updatePassword(password);

            commit('setStatusSuccess', 'updatePasswordStatus')

            resetAuthStatusByNameDelayed('updatePasswordStatus')
        } catch (error) {
            const { message } = errorTranslate(error);
            if (error.code !== "auth/requires-recent-login") {
                commit('setStatusFail', { statusName: 'updatePasswordStatus', error: message });

                resetAuthStatusByNameDelayed('updatePasswordStatus')
            } else {
                dispatch("reauthenticate")
                .then(() => dispatch("updatePassword", password))
                .catch(error => {
                    const { message } = errorTranslate(error);

                    commit('setStatusFail', { statusName: 'updatePasswordStatus', error: message });

                    resetAuthStatusByNameDelayed('updatePasswordStatus')
                });
            }
        }
    },

    async deleteAccount({ commit, dispatch }) {
        commit('setStatusLoading', 'deleteAccountStatus')

        const user = await getCurrentUser();

        try {
            await user.delete();

            commit('setStatusSuccess', 'deleteAccountStatus')

            dispatch('signOut');

            window.location.reload(true);
        } catch (error) {
            const { message } = errorTranslate(error);

            if (error.code !== "auth/requires-recent-login") {
                commit('setStatusSuccess', { statusName: 'deleteAccountStatus', error: message });

                resetAuthStatusByNameDelayed('deleteAccountStatus')
            } else {
                dispatch("reauthenticate")
                .then(() => dispatch("deleteAccount"))
                .catch(error => {
                    const { message } = errorTranslate(error);

                    commit('setStatusFail', { statusName: 'deleteAccountStatus', error: message });

                    resetAuthStatusByNameDelayed('deleteAccountStatus')
                });
            }
        }
    },

    async verification({ commit }) {
        commit('setStatusLoading', 'verificationStatus')

        const user = await getCurrentUser();

        try {
            await functions().httpsCallable("sendNewVerificationEmail")(user.uid);

            commit('setStatusSuccess', 'verificationStatus');
        } catch (error) {
            const { message } = errorTranslate(error);

            commit('setStatusFail', { statusName: 'verificationStatus', error: message });
        }

        resetAuthStatusByNameDelayed('verificationStatus')
    }
};
const getters = {
    isAdmin: state => {
        const { token } = state.user || {};
        const { claims } = token || {};
        const { admin } = claims || {};

        return admin === true;
    },
    isOwner: state => id => {
        const { uid } = state.user || {};
        return id && uid === id;
    },
    ...storeGetterStatusCreator(),
    getAuthUserEmail: state => state.authUserMail,
    getIsUserSignedIn: state => state.isUserSignedIn
};

const mutations = {
    initialized(state, value) {
        state.initialized = !!value;
    },
    setUserSignedIn(state, { authUserMail, isUserSignedIn }) {
        state.authUserMail = authUserMail
        state.isUserSignedIn = isUserSignedIn
    },
    setSubscriber(state, payload) {
        state.subscriber = payload
    },
    resetAuthStatuses(state) {
        authStatuses.forEach(status => {
            state[status] = cloneDeep(initialState[status])
        })
    },
    resetAuthStatus(state, stateName) {
        state[stateName] = cloneDeep(initialState[stateName]);
    },
    reset(state) {
        for (let prop in state) {
            state[prop] = cloneDeep(initialState[prop])
        }
    },
    ...storeMutationStatusCreator()
};

store.registerModule("AUTH", {
    namespaced: true,
    state: cloneDeep(initialState),
    actions: {
        ROUTE_CHANGE_START: {
            root: true,
            handler({ commit }, route) {
                if (["auth-signin", "auth-signup", "auth-password"].includes(route.name) && route.name !== route.from.name) {
                    commit('resetAuthStatuses');
                }
            }
        },
        ...actions
    },
    mutations,
    getters
});

function resetAuthStatusByNameDelayed(statusName) {
    setTimeout(() => {
        store.commit('AUTH/resetAuthStatus', statusName)
    }, 2500)
}
