import { getCurrentUser } from "@web/firebase";
import firebase, { firestore } from "firebase";

const { auth } = firebase;

export async function createUserProfile(user) {
    const isNewUser = user && user.additionalUserInfo && (user.additionalUserInfo.isNewUser || !user.user.metadata.lastSignInTime);

    if (!isNewUser) {
        return;
    }

    switch (user.additionalUserInfo.providerId) {
        case auth.GoogleAuthProvider.PROVIDER_ID:
            await updateUserProfile({
                email: user.user.email,
                firstName: user.additionalUserInfo.profile.given_name || null,
                lastName: user.additionalUserInfo.profile.family_name || null
            });

            break;
        case auth.FacebookAuthProvider.PROVIDER_ID:
            await updateUserProfile({
                email: user.user.email,
                firstName: user.additionalUserInfo.profile.first_name || null,
                lastName: user.additionalUserInfo.profile.last_name || null
            });

            break;
        case auth.GithubAuthProvider.PROVIDER_ID:
            await updateUserProfile({
                email: user.user.email
            });

            break;
    }
}

export async function updateUserProfile(payload) {
    const user = await getCurrentUser();

    const profileRef = firestore()
        .collection("users")
        .doc(user.uid);

    await profileRef.set(payload, { merge: true });
}
