import store from "../store";
import { fetchExpertises, fetchLanguages, fetchProfessions } from '@web/api/common-api'
import { fetchCountriesPhones } from '@web/services'

const state = {
    professions: [],
    expertises: [],
    languages: [],
    languageLevels: [],
    countriesPhones: []
};

const getters = {
    getExpertises: (state) => state.expertises.map(exp => {
        const { id, ...rest } = exp

        return {
            expertiseId: id,
            ...rest
        }
    }),
    getProfessions: (state) => state.professions,
    getProfessionsWithoutOther: (state) => state.professions.filter(profession => profession.value !== 'OTHER'),
    getLanguages: (state) => state.languages,
    getLanguageLevels: (state) => state.languageLevels,
    getCountriesPhones: (state) => state.countriesPhones,
    getDefaultPhoneCountry: (state) => state.countriesPhones.find(cPc => cPc.code === 'SVK')
};

const actions = {
    async fetchExpertises({ commit }) {
        const response = await fetchExpertises()

        commit('setExpertises', response)
    },
    async fetchProfessions({ commit }) {
        const response = await fetchProfessions()

        commit('setProfessions', response)
    },
    async fetchLanguages({ commit }) {
        const { languages, languageLevels } = await fetchLanguages()

        commit('setLanguages', languages)
        commit('setLanguageLevels', languageLevels)
    },
    async fetchCountriesPhones({ commit }) {
        const response = await fetchCountriesPhones()

        commit('setCountriesPhones', response)
    },
    loadEnums({ dispatch }) {
        dispatch('fetchExpertises')
        dispatch('fetchProfessions')
    },
};

const mutations = {
    setExpertises(state, payload) {
        state.expertises = payload
    },
    setProfessions(state, payload) {
        state.professions = payload
    },
    setLanguages(state, payload) {
        state.languages = payload
    },
    setLanguageLevels(state, payload) {
        state.languageLevels = payload
    },
    setCountriesPhones(state, payload) {
        state.countriesPhones = payload
    }
};

store.registerModule("ENUMS", {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
});
