<template>
    <button :class="classes" v-bind="$attrs" :type="type" :disabled="disabled" v-on="listeners">
        <span class="tw-absolute tw-transform tw-inline-flex tw-items-center tw-justify-center">
            <transition
                enter-active-class="tw-transition tw-transform tw-duration-300 tw-ease-out-quad"
                leave-active-class="tw-transition tw-transform tw-duration-300 tw-ease-in-quad"
                enter-class="tw--translate-x-4 tw-opacity-0"
                enter-to-class="tw--translate-x-0 tw-opacity-100"
                leave-class="tw--translate-x-0 tw-opacity-100"
                leave-to-class="tw-translate-x-4 tw-opacity-0"
                mode=""
            >
                <span v-if="success" class="tw-inline-flex tw-items-center tw-justify-center">
                    <k-icon name="done" size="24" />
                </span>
            </transition>
        </span>

        <span class="tw-absolute tw-transform tw-inset-x-1/2 tw-inline-flex tw-items-center tw-justify-center">
            <transition
                enter-active-class="tw-transition tw-transform tw-duration-300 tw-ease-out-quad"
                leave-active-class="tw-transition tw-transform tw-duration-300 tw-ease-in-quad"
                enter-class="tw--translate-x-4 tw-opacity-0"
                enter-to-class="tw--translate-x-0 tw-opacity-100"
                leave-class="tw--translate-x-0 tw-opacity-100"
                leave-to-class="tw-translate-x-4 tw-opacity-0"
                mode=""
            >
                <div v-if="!success && loading" class="tw-inline-flex tw-items-center tw-justify-center">
                    <v-progress-circular indeterminate :size="24" :width="2"></v-progress-circular>
                </div>
            </transition>
        </span>

        <transition
            enter-active-class="tw-transition tw-duration-300 tw-ease-out-quad"
            leave-active-class="tw-transition tw-duration-300 tw-ease-in-quad"
            enter-class="tw-opacity-0"
            enter-to-class="tw-opacity-100"
            leave-class="tw-opacity-100"
            leave-to-class="tw-opacity-0"
            mode="out-in"
        >
            <span v-if="loading || success" key="invisible" class="tw-invisible tw-inline-flex tw-justify-center tw-items-center">
                <slot></slot>
            </span>

            <span v-else key="visible" class="tw-inline-flex tw-justify-center tw-items-center">
                <slot></slot>
            </span>
        </transition>
    </button>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        success: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: "regular"
        },
        icon: {
            type: Boolean,
            default: false
        },
        withIcon: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: "gray-outlined"
        },
        type: {
            type: String,
            default: "button"
        },
        to: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: false
        },
        href: String,
        rel: String,
        target: String
    },
    computed: {
        listeners() {
            const self = this;

            return {
                ...this.$listeners,
                click(evt) {
                    if (self.$listeners.click) {
                        self.$listeners.click(evt);
                    }

                    if (self.to) {
                        if (self.target) {
                            let routeData = self.$router.resolve(self.to);

                            window.open(routeData.href, self.target);
                        } else {
                            self.$router.push(self.to);
                        }
                    } else if (self.href) {
                        const link = document.createElement("a");
                        link.setAttribute("href", self.href);
                        link.setAttribute("target", self.target || "_blank");
                        link.setAttribute("rel", self.rel || "noopener noreferrer");
                        link.dispatchEvent(new MouseEvent("click"));
                    }
                }
            };
        },
        classes() {
            return {
                "tw-rounded-full": this.rounded,

                // Lg
                "tw-px-10 tw-py-3 tw-text-base tw-leading-7": this.size === "lg",

                // Disabled
                "tw-opacity-25 tw-cursor-not-allowed": this.disabled === true,

                // Icon only
                "tw-p-0 tw-h-11 tw-w-11": this.icon && !this.withIcon,

                // Red Outlined
                "tw-button--base tw-bg-red-light hover:tw-border-red-light active:tw-border-red-light": this.color === "red-outlined",
                "tw-button--base tw-bg-red-light tw-border tw-border-solid tw-border-red-light tw-text-red hover:tw-border-red-alt active:tw-border-red-alt": this.color === "red-outlined" && !this.disabled,

                // Gray Outlined
                "tw-button--base tw-bg-gray-100 hover:tw-border-gray-100 active:tw-border-gray-100": this.color === "gray-outlined",
                "tw-button--base tw-bg-gray-100 tw-border tw-border-solid tw-border-gray-100 tw-text-gray-800 hover:tw-border-gray-300 active:tw-border-gray-300": this.color === "gray-outlined" && !this.disabled,

                // Primary
                "tw-button": this.color === "primary",

                // Primary inverted
                "tw-button--inverted": this.color === "primary-inverted",

                // Secondary
                "tw-button--secondary": this.color === "secondary",

                // Outline
                "tw-button--outline": this.color === "outline",

                // Regular
                "tw-button--regular": this.color === "regular",

                // Text
                "tw-button--text": this.color === "text"
            };
        }
    }
};
</script>
