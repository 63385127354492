import { api } from "./config";

async function fetchExpertises() {
    const { data } = await api.get('/expertises',)

    return data
}

async function fetchProfessions() {
    const { data } = await api.get('/professions')

    return data
}

async function fetchLanguages() {
    const { data } = await api.get('/languages')

    return data
}

export {
    fetchExpertises,
    fetchProfessions,
    fetchLanguages
}
