module.exports = {
    env: "development",
    apiUrl: "https://koderiatest.objectify.sk/rest/koderia", // http://localhost:8080       https://koderiatest.objectify.sk
    adminUrl: "https://admin-koderia-sk-dev.web.app",
    baseUrl: "https://koderia-sk-dev.web.app",
    firebaseRegion: "us-central1",
    firebaseConfig: {
        apiKey: "AIzaSyBSQxeeFJhTIWICt5KQsz0B4lYvvR_kCIY",
        authDomain: "koderia-develop.firebaseapp.com",
        databaseURL: "https://koderia-develop.firebaseio.com",
        projectId: "koderia-develop",
        storageBucket: "koderia-develop.appspot.com",
        messagingSenderId: "571747164740",
        appId: "1:571747164740:web:44b952a645663bd8eb1211"
    },
};
