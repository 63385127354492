import 'firebase/functions'

import firebase from 'firebase/app'
import store from "../store"
import {
    fetchUserDetail,
    updateUserCvSettings,
    updateUserDetail,
    updateUserNotificationSettings
} from '@web/api/user-api'
import { cloneDeep } from 'lodash'
import { DEFAULT_LOCALE } from "@web/constants/language";
import {
    storeMutationStatusCreator,
    storeGetterStatusCreator,
    storeStateStatusesCreator
} from '@web/utils/store-status-creator'

const { functions } = firebase

const userStatuses = [
    'profileStatus',
    'updateProfileStatus',
    'cvSettingsStatus',
    'updateCvSettingsStatus',
    'generatePdfStatus'
]

const initialState = {
    profile: null,
    cvSettings: null,
    ...storeStateStatusesCreator(userStatuses)
};

const actions = {
    async fetchProfile({ commit }) {
        commit('setStatusLoading', 'profileStatus')

        const { profile, cvSettings } = await fetchUserDetail()

        commit('setProfile', profile)
        commit('setCvSettings', cvSettings)
        commit('setStatusSuccess', 'profileStatus')
    },
    async updateProfile({ state, commit }, detail) {
        commit('setStatusLoading', 'updateProfileStatus')

        try {
            const profile = await updateUserDetail({ ...state.profile, ...detail })

            commit('setProfile', profile)
            commit('setStatusSuccess', 'updateProfileStatus')

            setTimeout(() => {
                commit('resetUserStatus', 'updateProfileStatus');
            }, 2500);
        } catch {
            commit('setStatusFail', { statusName: 'updateProfileStatus' })
        }
    },

    async updateNotificationSettings({ state, commit }, newNotificationSettings) {
        commit('setStatusLoading', 'updateProfileStatus')

        try {
            const notificationSettings = await updateUserNotificationSettings(newNotificationSettings)

            commit('setProfile', { ...state.profile, ...notificationSettings , })
            commit('setStatusSuccess', 'updateProfileStatus')

            setTimeout(() => {
                commit('resetUserStatus', 'updateProfileStatus');
            }, 2500);
        } catch {
            commit('setStatusFail', { statusName: 'updateProfileStatus' })
        }
    },
    async updateCvSettings({ commit }, settings) {
        commit('setStatusLoading', 'updateCvSettingsStatus')

        try {
            const response = await updateUserCvSettings(settings)

            commit('setCvSettings', response)
            commit('setStatusSuccess', 'updateCvSettingsStatus')

            setTimeout(() => {
                commit('resetUserStatus', 'updateCvSettingsStatus')
            }, 2500)
        } catch {
            commit('setCvSettings', null)
            commit('setStatusFail', { statusName: 'updateCvSettingsStatus' })
        }
    },
    async generatePdf({ commit, state }) {
        commit('setStatusLoading', 'generatePdfStatus')

        const id = state.profile.id
        const { token, language } = state.cvSettings

        try {
            functions().httpsCallable('generatePdf')({ id, token, language })

            setTimeout(() => {
                commit('setStatusSuccess', 'generatePdfStatus')
            }, 5000)
        } catch (e) {
            commit('setStatusFail', { statusName: 'generatePdfStatus', error: e.message })
        }
    }
};

const mutations = {
    resetUserStatus(state, stateName) {
        state[stateName] = cloneDeep(initialState[stateName]);
    },
    setProfile(state, payload) {
        state.profile = payload;
    },
    updateUserProfile(state, payload) {
        state.profile = {
            ...state.profile,
            ...payload
        }
    },
    setCvSettings(state, payload) {
        state.cvSettings = payload
    },
    addNewJobApplication(state, payload) {
        state.profile.jobApplications.push(payload);
    },
    RESET(state) {
        for (let prop in state) {
            state[prop] = cloneDeep(initialState[prop]);
        }
    },
    ...storeMutationStatusCreator()
};

const getters = {
    getUserProfile: state => cloneDeep(state.profile),
    getHasUserProfile: state => !!state.profile,
    getHasUserGdprAccepted: state => state.profile?.gdprAgreementAccepted,
    getUserAppLanguage: state => state.profile?.appLanguage?.value.toLowerCase() || DEFAULT_LOCALE,
    getCvSettings: state => cloneDeep(state.cvSettings),
    ...storeGetterStatusCreator()
}

store.registerModule("USER", {
    namespaced: true,
    state: cloneDeep(initialState),
    actions,
    getters,
    mutations
});
